import Typed from "typed.js";
import _ from "./accordion.js";
import { cleanForm } from "./form.js";
import { langData, typedStrings, benefitsShortData, benefitsCards } from "./data.js";
import { recognizeUserLang } from './lang.js'

// Short Benefits Generation

const benefitsShortContainer = document.getElementById("benefits-short-container");

benefitsShortData.forEach((card, i) => {
	benefitsShortContainer.insertAdjacentHTML("beforeend", `
      <div>
        <h3 id="short-benefit-card-heading-${i}">${card.h3}</h3>
        ${card.svg}
      </div>
    `)
})

const menuBtn = document.querySelector("#menu-toggle");
const navMenu = document.querySelector("nav");
const header = document.querySelector("header");

// NavBar

menuBtn.addEventListener("click", () => {
	if (navMenu.style.visibility !== "visible") {
		navMenu.style.visibility = "visible";
		header.style.position = "fixed";
		header.style.top = "0";
		header.style.zIndex = "100";
		menuBtn.classList.add("nav-open");
	} else {
		navMenu.style.visibility = "hidden";
		header.style.position = "static";
		menuBtn.classList.remove("nav-open");
	}
});

// Register Form

const formOverlay = document.querySelector(".form-overlay");
const slider = document.getElementById("slider");
const switchCheckbox = document.getElementById("switch-checkbox");
const closeFormBtn = document.getElementById("form-close-btn");
const navFormLink = document.getElementById("nav-form-link");

let curMode = "Email"
const getOppositeMode = (mode) => mode === "Email" ? 'Telegram' : "Email"

function setFormStructure(option) {
	if (option === "Email") {
		formOverlay.classList.remove("form-tg-mode");
	} else {
		formOverlay.classList.add("form-tg-mode");
	};

	slider.style.justifyContent = option === 'Email' ? 'end' : 'start';
	slider.textContent = getOppositeMode(option);
	slider.classList.remove(`${getOppositeMode(option).toLowerCase()}-icon`);
	slider.classList.add(option.toLowerCase() + "-icon");
};

navFormLink.addEventListener("click", () => {
	formOverlay.classList.add("form-active");
	navMenu.style.visibility = "hidden";
	header.style.position = "static";
	menuBtn.classList.remove("nav-open");
});

slider.addEventListener("click", (e) => {
	curMode = getOppositeMode(curMode);
	setFormStructure(curMode);
});

closeFormBtn.addEventListener("click", (e) => {
	e.preventDefault();

	curMode = "Email";
	setFormStructure(curMode);
	switchCheckbox.checked = false;

	cleanForm();

	formOverlay.classList.remove("form-active");
});

const benefitsLongContainer = document.getElementById("benefits-long-container");
let pausedTypedStrings = typedStrings.en.map((str) => "^1000 " + str);

recognizeUserLang(setLanguage)
function setLanguage(lang) {
	// Main Page
	pausedTypedStrings = typedStrings[lang].map((str) => "^1000 " + str);

	for (let key in langData) {
		document.getElementById(`${key}`).textContent = langData[key][lang];
	};

	// Benefits Cards Generation
	benefitsLongContainer.replaceChildren()
	benefitsCards[lang].forEach((el, i) => {
		i % 2 === 0
			? benefitsLongContainer.insertAdjacentHTML(
				"beforeend",
				`
        <div class="benefit-card">
              <div class="benefit-card__text-box">
                <h3>${el.h1}</h3>
                <span>${el.text1}</span>
                <h3>${el.h2}</h3>
                <span>${el.text2}</span>
                <button class="register-btn">${el.btnText}</button>
              </div>
              <img src="${el.img.src}" alt="${el.img.alt}">
            </div>`
			)
			: benefitsLongContainer.insertAdjacentHTML(
				"beforeend",
				`
        <div class="benefit-card">
              <img src="${el.img.src}" alt="${el.img.alt}">
              <div class="benefit-card__text-box">
                <h3>${el.h1}</h3>
                <span>${el.text1}</span>
                <h3>${el.h2}</h3>
                <span>${el.text2}</span>
                <button class="register-btn">${el.btnText}</button>
              </div>
            </div>`
			);
	});
};

// Register Buttons Logic

const registerBtns = document.querySelectorAll(".register-btn");

registerBtns.forEach((btn) => {
	btn.addEventListener("click", () => {
		formOverlay.classList.add("form-active");
	});
});

// Typed Text

const typedTextSpan = document.getElementById("typed-text");
const typedTextLastIndex = pausedTypedStrings.length - 1;
const mainRegisterBtn = document.getElementById("main-register-btn");
let firstTypedIncome = true;

function changeMainGradients(strI, lastStrI) {
	if (strI === 0 && !firstTypedIncome) {
		typedTextSpan.classList.remove(`typed-style-${lastStrI}`);
		mainRegisterBtn.classList.remove(`main-btn-style-${lastStrI}`)
	} else {
		typedTextSpan.classList.remove(`typed-style-${strI - 1}`);
		mainRegisterBtn.classList.remove(`main-btn-style-${strI - 1}`)
	}
	typedTextSpan.classList.add(`typed-style-${strI}`);
	mainRegisterBtn.classList.add(`main-btn-style-${strI}`);
}

const typeOptions = {
	strings: pausedTypedStrings,
	typeSpeed: 90,
	backSpeed: 70,
	backDelay: 3000,
	loop: true,
	loopCount: Infinity,
	preStringTyped: (arrPos) => { changeMainGradients(arrPos, typedTextLastIndex) },
	onComplete: () => {
		firstTypedIncome = false;
	}
};

const typed = new Typed(typedTextSpan, typeOptions);