const form = document.getElementById('form');
const usernameLabel = document.getElementById('username-label');
const emailLabel = document.getElementById('email-label');

const usernameErrTxt = document.getElementById('username-field-error');
const emailErrTxt = document.getElementById('email-field-error');

export const usernameField = document.getElementById('username-field');
export const emailField = document.getElementById('email-field');

const emptyFieldError = 'This field is required';
const invalidEmailError = 'This email is invalid';

const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

const setError = (label, field, txt, error) => {
	label.classList.add('error');
	txt.innerText = error;

	field.onchange = () => {
		txt.innerText = '';
		label.classList.remove('error');
		field.onchange = null;
	};
};

export const cleanForm = () => {
	emailLabel.classList.remove('error');
	usernameLabel.classList.remove('error');

	usernameField.value = '';
	emailField.value = '';

	usernameField.onchange = null;
	emailField.onchange = null;
};

const setEmailError = (err) => {
	setError(emailLabel, emailField, emailErrTxt, err);
};

async function sendData(info) {
	fetch("https://insaider.online/community/sign-up", {
		method: "POST",
		headers: {
			"Content-Type": 'application/json'
		},
		body: JSON.stringify(info)
	})
		.then(response => {
			if (response !== "") response.json();
		}
		)
		.then(data => console.log(data))
		.catch(e => console.log(e))
}

form.onsubmit = (e) => {
	e.preventDefault();

	const username = usernameField.value;
	const email = emailField.value;
	let err = false;

	if (username === '') {
		setError(usernameLabel, usernameField, usernameErrTxt, emptyFieldError);
		err = true;
	};

	switch (email) {
		case '':
			setEmailError(emptyFieldError);
			err = true;
			break;
		default:
			if (!validateEmail(email)) {
				setEmailError(invalidEmailError);
				err = true;
			};
	};;

	if (err) {
		return;
	};

	const userInfo = {
		"name": username,
		"email": email,
		"community_id": 1
	};

	sendData(userInfo);
	window.location.replace("/successful-registration" + window.location.hash);
	cleanForm();
};
